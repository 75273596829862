<template>
  <span>
    <span class="text-bold">{{ price[0] }}</span>
    <small class="text-[0.7em]">
      {{ price[1] ? '.' + price[1] : '' }} грн
    </small>
  </span>
</template>
<script setup>
const props = defineProps({
  price: {
    type: Number,
    default: 0
  }
})

const price = computed(() => `${props.price.toFixed(2)}`.split('.'))
</script>
